/* YourComponent.css */

.softwere_container {
  position: relative;
  width: 100%;
  height: 100vh; /* Adjust the height as needed */
  /* background: linear-gradient(
      0deg,
      rgba(6, 6, 6, 0.4) 10%,
      rgba(6, 6, 6, 0.4) 100%
    ),
    url("../../Assets/softsol.jpeg"), center/cover no-repeat; */
    background: url("/src/Assets/softsol.jpeg") center/cover no-repeat;
    backdrop-filter: blur(4px);
    background-attachment: fixed !important;
}

.content {
  position: absolute;
  top: 50%;
  left: 52%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  margin-left: -25rem;
  font-family: "Poppins", sans-serif;
  backdrop-filter: blur(4px);
}

.content h1 {
  font-size: 3.7em;
  margin-bottom: 10px;
 
}

.content h4 {
  font-size: 1.4em;
  line-height: 1.5;
  max-width: 60vw;
}
.para-img {
  background-color: black;
  margin-top: -1.4rem;
  padding-bottom: 3rem;
  border-radius: 20px;
}
.paras {
  max-width: 80vw; /* Adjust the maximum width as per your layout */
  margin: 0 auto; /* Center the paragraphs */
  padding: 50px 20px; /* Add some padding for spacing */
  text-align: justify; /* Justify the text for a clean look */
}

.paras p {
  margin-bottom: 20px;
  font-size: 1.3em;
  line-height: 1.6;
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
}

.s-img {
  display: flex;
  /* flex-direction: wrap; */
  justify-content: space-around;
  padding: 0 8rem;
  margin-top: 5rem;
  border: 20px;
}
.s-img img {
  height: 40rem;
  width: 23rem;
  border-radius: 20px;
}
/* Button 1 */
.Btn {
  width: 190px;
  height: 50px;
  background-color: transparent;
  color: white;
  font-size: 20px;
  font-weight: bold;
  border-radius: 20px;
  /* border-width: 2px; */
  border: 1px solid #38b6ff;
  transition: all 0.3s;
  margin-left: 6rem;
  transform: translateY(5px);
}

.Btn:hover {
  transform: translateY(-10px);
  background-color:#38b6ff;
  color: #fff;
  z-index: 100;
}

.Btn::after {
  transform: translateY(10px);
  background-color:#0d5477 ;
}
.s-img img,
.Btn:hover {
  transform: translateY(-10px);
}
@media screen and (max-width: 768px) {
  .content {
    margin: 0;
    max-width: 50vw;
  }
  .content h1 {
    font-size: 3em;
  }

  .content h4 {
    font-size: 1.2em;
    max-width: 80vw;
    margin: 0;
  }
  .s-img {
    flex-wrap: wrap;
    /* width: 50vw; */
  }
  .s-img img {
    width: 90vw;
  }
  .s-img button {
    align-items: center;
    flex-wrap: wrap;
  }
  .btn-img {
    margin-top: 2rem;
  }
}
