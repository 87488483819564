#Management_BackGround_Image {
  background: url(/src/Assets/let\ build\ background.png);
  background-size: cover;
  min-height: 70vh;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment:fixed;
}
#managementpage_span1{
  color: #fff;
  font-size: 2.5vw;
  font-weight: 600;
  line-height: 1.2;
  font-family: "Poppins", sans-serif;
}
#Managemmentpgae_Heading {
  color: #38b6ff;
  font-size: 2.5vw; /* Adjusted font size based on viewport width */
  font-weight: 600;
  line-height: 1.2;
  font-family: "Poppins", sans-serif;
}

#Managemmentpgae_Heading {
  position: relative;
  top: 2rem; /* Adjusted top margin for responsiveness */
  margin-left: 5.7%; /* Adjust margin for responsiveness */
}

#managementPage_Team_card {
  overflow: hidden;
  border-radius: 20px;
  gap: 40px;
  height: 70vh;
  border: 1px solid black;
  background-color: whitesmoke;
  /* margin-top: 5%; */
  /* margin-left: 5%; */

  /* width: 90% !important; */
}

#managementPage_Team_Image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 20px;
}

#ManagementPage_up_body {
  padding: 2rem; /* Adjusted padding for responsiveness */
  column-gap: 20px;
}

.slick-slide > div {
  margin: 1rem 0.5rem; /* Adjusted margin for responsiveness */
  max-width: 100%;
}

.slick_list {
  margin: 0 -0.5rem; /* Adjusted margin for responsiveness */
}

.slick-next,
.slick-prev {
  visibility: hidden;
}

.slick-dots {
  background-color: transparent;
  display: flex !important;
  justify-content: center !important;
}

.slick-dots button {
  margin: -0.5rem 0.25rem; /* Adjusted margin for responsiveness */
  border-radius: 50% !important;
  border: 1px solid #fff !important;
  color: #38b6ff;
  font-size: 1.5rem; /* Adjusted font size for responsiveness */
}

.slick-active button {
  background-color: #38b6ff !important;
  color: transparent !important;
}

@media (max-width: 768px) {
  #Managemmentpgae_Heading,
  #managementpage_span1 {
    font-size: 3.5vw; 
    margin-left: 2%; 
  }
}

@media (max-width: 500px) {
  #Managemmentpgae_Heading,
  #managementpage_span1 {
    font-size: 5vw; 
  }
  #ManagementPage_Main_Body{
    height: 75vh;
    text-align: center;
  }
  #managementPage_Team_card {
   height: 50vh;
  }
  #managementPage_Team_card {
    padding: 1rem; 
  }
}
.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: ' ';
  text-align: center;
  opacity: .25;
  color: transparent !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: .75;
  color: transparent !important;
}
