.metholodogy_section {
  padding-top: 2rem;
  width: 100vw;
  /* height: 130vh; */
  background-color: #121212;
 
}
.methodology {
  /* width: calc(100vw-100px); */
  /* aspect-ratio: 2.4; */
  background-color: #121212;
  /* color: white; */
  /* margin: 50px; */
 
  position: relative;
  
}
.methodology svg {
  width: 100%;
  /* margin: 20px !important; */
}
.methodology .block {
  position: absolute;
  width: 8vw;
}
.methodology .block h5 {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1vw;
}

/* .methodology img:nth-child(6){top: 10%; left: 60%;} */
.direction1 {
  width: 7% !important;
  position: absolute !important;
  top: 12vw;
  left: 18.5%;
}
.direction2 {
  width: 8% !important;
  position: absolute !important;
  top: 14vw;
  left: 36%;
}
.direction3 {
  width: 7% !important;
  position: absolute !important;
  top: 12vw;
  left: 55.5%;
}
.direction4 {
  width: 8% !important;
  position: absolute !important;
  top: 14vw;
  left: 73.5%;
}
.methology_heading,
.metholody_parargraph1 {
  color: #fff;
  padding: 1em 5rem;
  font-family: 'poppins', sans-serif;
}
.methology_heading {
  font-size: 2rem;
}
.metholody_parargraph1 {
  font-size: 1.3rem;
}
.heads span {
  color: rgb(57, 106, 204);
  width: 30%;
}
@media (max-width: 768px) {
  .methology_heading {
    font-size: 1.5rem;
  }
  .metholody_parargraph1 {
    font-size: 1.2rem;
    text-align: center;
  }
  .m_img{
    /* margin: 0px 150px; */
  }
}
@media (max-width: 400px) {
  .methology_heading{
    font-size: 18px;
    padding: 0rem 1rem;
  }
  .metholody_parargraph1{
    font-size: 12px;
    text-align: left;
    padding: 0rem 1rem;
  }
  
  .m_img{
    margin: 50px;
  }
}
@media (max-width: 1390px) {
  .methodology{
    display: none;
  }


}
@media (max-width: 500px) {
  .min-lap{
    display: none;
  }


}
@media (min-width: 500px) {
  .m_mob{
    display: none;
  }


}
.min_img{

 
  align-self: center;
}
