.Digital_bgImage {
  background: url(/src/Assets/digital\ marketing\ background.jpeg),
    linear-gradient(10deg, rgba(6, 6, 6, 0.4) 0%, rgba(6, 6, 6, 0.4) 100%);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 570px;
  background-attachment: fixed;
}

.Digital_content-body {
  position: absolute;
  top: 50%;
  left: 39%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.Digital_heading-1 {
  font-size: 60px;
  color: white;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: -540px;
  font-weight: 700;
  line-height: 1;
  font-family: "Poppins", sans-serif;
  padding-bottom: 20px;
}

.Digital_paragraph_1 {
  margin-left: -370px;
  font-size: 37px;
  line-height: normal;
  font-weight: 500;
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
}

.Digital-all-paragraph {
  margin-top: 50px;
  margin-left: 50px;
}

.Digital_paragraph_2,
.Digital_paragraph-3,
.Digital_paragraph_4 {
  color: whitesmoke;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  padding: 4px 30px;
  font-weight: 400;
}

.Digital_paragraph-3 {
  padding: 10px 30px;
}

.Digital_paragraph_4 {
  padding: 20px 30px;
}

.Digital_card_container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 45px;
  margin-top: 40px;
}

.Digital_card {
  width: 25%; 
  background-color: transparent;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  transition: transform 0.6s ease;
}

.Digital_card:hover {
  transform: translateY(-20px);
}

.Digital_card-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 20px;
}

.Digital_card_content {
  padding: 20px;
}

.Digital_card_button {
  font-size: 20px;
  width: 100%; 
  height: 60px;
  font-weight: 700;
  border-radius: 60px;
  border: 1px solid #9747ff;
  background: linear-gradient(
    123deg,
    rgba(58, 129, 191, 0.08) 6.2%,
    rgba(217, 217, 217, 0) 93.68%,
    rgba(120, 84, 174, 0.03) 93.68%
  );
  color: whitesmoke;
  flex-shrink: 0;
  line-height: 60px;
  text-align: center;
}

.Digital_card_button:hover {
  background-color: #10b7fd;
  transform: translateY(-10px);
}

@media (max-width: 768px) {
  .Digital_heading-1 {
    font-size: 40px; 
    /* margin-left: -900px;  */
    margin-left: 0.5rem;
    text-align: center;
  }

  .Digital_paragraph_1 {
    font-size: 25px; 
    /* margin-left: -200px;  */
    text-align: center;
    margin-left: 0.5rem;
  }

  .Digital-all-paragraph {
    margin-top: 20px; 
    margin-left: 20px; 
  }

  .Digital_paragraph_2,
  .Digital_paragraph-3,
  .Digital_paragraph_4 {
    font-size: 18px; 
    padding: 4px 10px; 
  }

  .Digital_card {
    width: 80%; 
  }

  .Digital_card_button {
    font-size: 18px; 
  }
}


/* 
@media (min-width: 768px) and (max-width: 1024px) {
  .Digital_heading-1 {
    position: relative;
    left: 180px;
    top: -300px;
   
  }
  .Digital_paragraph_1 {
    position: relative;
    left: 150px;
    top: -300px;
  }
  .Digital_card_container {
    gap: 70px;
  }
  .Digital_card {
    width: 80%;
  }
  .Digital_card_button {
    position: relative;
    margin-left: 120px;
    text-align: center;
  }
}
@media (min-width: 600px) and (max-width: 767px) {
  .Digital_heading-1 {
    position: relative;
    left: 250px;
    text-align: center;
    top: -300px;
  }
  .Digital_paragraph_1 {
    position: relative;
    left: 180px;
    top: -300px;
    font-size: 30px;
  }
  .Digital_card {
    width: 80%;
  }
  .Digital_card_button {
    position: relative;
    margin-left: 80px;
    text-align: center;
  }
  
}


@media (min-width: 300px) and (max-width: 599px) {
  .Digital_heading-1 {
    position: relative;
    margin: 0;
    top: -300px;
    text-align: center;
    font-size: 35px;
  }
  .Digital_paragraph_1 {
    position: relative;
    left: 180px;
    top: -300px;
    font-size: 20px;
  }
  .Digital_content-body{
    top: 70%;
    text-align: center;
  }
} */
