.swiper {
  width: 100% !important;

  padding-top: 50px;
  height: 100%;
}
#Myswipey {
  margin: 10px;
  /* padding: 20px 270px 70px 270px; */
}
.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 330px;
  padding-bottom: 50px;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2);
  /* filter: blur(4px); */
}
.swiper-slide-active {
  filter: blur(0px);
}
 
.swiper-slide img {
  display: block;
  object-fit: cover;
  width: 100%;
  border-radius: 20px;
}
.Testimonialpage_box {
  position: relative;
  width: 100%;
}
.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-left {
  /* background-image: none; */
  display: none;
}
.testimonialpage_span {
  color: #38b6ff;
  font-family: "Poppins", sans-serif;
}
.TestimonalPage_content {
  color: whitesmoke;
  font-size: 20px;
  padding: 10px 50px;
  font-family: "Poppins", sans-serif;
  margin-left: 2rem;
}
@media screen and (max-width: 768px) {
  #Myswipey {
    margin: 0px;
    padding: 20px;
  }

  .swiper-slide {
    width: 100%;
  }

  .TestimonalPage_content {
    padding: 10px;
    font-size: 20px;
  }
}