#NsmsPage_Hero_Image {
  position: relative;
  width: 100%;
  /* height: auto; */
  height: 500px;
  background-position: cover;
  background: url(/src/Assets/smsbackground.jpeg);
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}
#NsmsPage_Hero_Image::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
}
#nsmspage_hero_content {
  position: relative;
  width: 60%;
}
#nsmspahe_heading_One {
  color: whitesmoke;
  font-size: 60px;
  position: relative;
  top: 200px;
  left: 50px;
  font-family: "Poppins", sans-serif;
}
#nsmspage_Paragraph_one {
  color: whitesmoke;
  font-size: 25px;
  position: relative;
  top: 210px;
  left: 60px;
  font-family: "Poppins", sans-serif;
}
@media screen and (min-width: 300px) and (max-width: 400px) {
  #nsmspahe_heading_One {
    font-size: 30px;
  }
  #nsmspage_Paragraph_one {
    font-size: 15px;
    width: 221px;
    position: absolute;
    left: 50px;
    top: 250px;
  }
}
@media screen and (min-width: 401px) and (max-width: 500px) {
  #nsmspahe_heading_One {
    font-size: 30px;
  }
  #nsmspage_Paragraph_one {
    font-size: 15px;
    width: 350px;
    position: absolute;
    left: 50px;
    top: 250px;
  }
}
@media screen and (min-width: 501px) and (max-width: 700px) {
  #nsmspahe_heading_One {
    font-size: 30px;
  }
  #nsmspage_Paragraph_one {
    font-size: 15px;
    width: 350px;
    position: absolute;
    left: 50px;
    top: 250px;
  }
}

/* 2nd */
#nsmspage_paras_all {
  margin: 20px auto;
}
#nsmspage_paras_One {
  color: whitesmoke;
  text-align: justify;
  /* width: 90%; */
  padding: 10px 50px;
  font-family: "Poppins", sans-serif;
  font-size: 1.3rem;
}
#nsmspage_paras_two {
  color: whitesmoke;
  text-align: justify;
  /* width: 90%; */
  padding: 10px 50px;
  font-family: "Poppins", sans-serif;
  font-size: 1.3rem;
}
#nsmspage_Paras_three {
  color: whitesmoke;
  text-align: justify;
  /* width: 90%; */
  padding: 10px 50px;
  font-family: "Poppins", sans-serif;
  font-size: 1.3rem;
}
#nsmspage_paras_four {
  color: whitesmoke;
  text-align: justify;
  /* width: 90%; */
  padding: 10px 50px;
  font-family: "Poppins", sans-serif;
  font-size: 1.3rem;
}
@media screen and (max-width: 400px) {
  #nsmspage_paras_all {
    width: 100%;
    letter-spacing: -0.5px;
  }
  #nsmspage_paras_four {
    letter-spacing: normal;
    hyphens: none;
  }
}

/* CARD SECTION CSS */
#nsmspage_card_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 90px;
}
#nsmspage_card_content_section {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
#nsmspage_Card {
  transition: transform 0.6s ease;
  flex: 1;
  margin: 20px 20px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
}
#nsmspage_Card:hover {
  transform: translateY(-20px);
}
#nsmspage_Card:hover #nsmspage_button-under {
  background: #38b6ff;
}
#nsmspage_image1 {
  width: 100%;
  height: 350px;
  border-radius: 20px;
}
#nsmspage_card_Button {
  /* padding: 10px 50px; */
}
#nsmspage_button-under {
  background-color: transparent;
  font-family: "Poppins", sans-serif;
  color: whitesmoke;
  padding: 10px 50px;
  border-radius: 20px;
  font-size: 20px;
  margin: 5px 0px;
  display: inline-block;
}
#nsmspage_button-under::before {
  content: "";
  position: absolute;
  background: linear-gradient(to left, #386fbb, to bottom left, #386fbb);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  margin: 10px;
}
@media (max-width: 768px) {
  #nsmspage_card_content_section {
    flex-direction: column;
  }
  #nsmspage_button-under {
    padding: 10px 30px;
  }
  #nsmspage_image1 {
    height: auto;
  }
}

/* LETS BUILD FUTURE TOGETHER CSS*/
#NSMSPAGE_CONTACT_BODY {
  position: relative;
  background: url(/src/Assets/let\ build\ background.png);
  min-height: 100vh;
  padding: 50px 150px;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  flex-direction: column;
}
#NSMSPAGE_FORM-CONTAINER {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
#NSMSPAGE_FORM_CONTACT_INFO {
  width: 90%;
  display: flex;
  flex-direction: column;
}
#NSMSPAGE_FORM-BOX {
  position: relative;
  padding: 20px 0;
  display: flex;
}
#NSMSPAGE_FORM_TEXT {
  color: whitesmoke;
  position: relative;
  /* left: -210px; */
  margin-left: -120px;
}
#NSMSPAGE_FORM_TEXT2 {
  font-family: "Poppins", sans-serif;
  font-size: 60px;
}
#NSMAPHE_FORM_TEXT3 {
  font-family: "Poppins", sans-serif;
  font-size: 55px;
}
#NSMSPAGE_SPAN_FORM {
  font-family: "Poppins", sans-serif;
  color: #38b6ff;
}
#NSMSPAGE_PARAGRAPH_ONE {
  font-family: "Poppins", sans-serif;
}
#NSMSPAGE_FORM {
  background: transparent;
  width: 500px;
  margin: 5px auto;
  max-width: 95%;
  border-radius: 4px;
  padding: 40px 10px;
}
#NSMSPAGE_FORM_HALF {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}
#NSMSPAGE_ITEMS_FORM {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
#NSMSPAGE_ALL_FORM {
  border: 1px solid #386fbb;
  border-bottom-color: #38b6ff;
  padding: 1px 10px;
  height: 450px;
  border-radius: 35px;
  width: 460px;
}
#NSMSPAGE_FORM_TITLE {
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
  letter-spacing: 2px;
  display: inline-block;
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: 600;
}
#NSMSPAGE_INPUT_BOX_Two {
  border-radius: 4px;
  border: 1px solid white;
  outline: none;
  padding: 19px;
  width: 100%;
  height: 40px;
  background: whitesmoke;
  font-size: 14px;
}
#NSMSPAGE_INPUT_BOX {
  border-radius: 4px;
  border: 1px solid white;
  outline: none;
  padding: 19px;
  width: 100%;
  height: 40px;
  background: whitesmoke;
  font-size: 14px;
}
#NSMSPAGE_Form_Full {
  margin-bottom: 24px;
}
#NSMSPAGE_Textarea_form {
  background: whitesmoke;
  border-radius: 10px;
  outline: none;
  padding: 10px 16px;
  width: 100%;
  height: 110px;
  font-size: 17px;
  margin-top: 30px;
}
#NSMSPAGE_Items_Forms_Select_box {
  width: 100%;
}
#NSMSPAGE_select {
  height: 40px;
  width: 100%;
  padding: 10px 15px;
  line-height: 22;
  outline: none;
  border-radius: 10px;
}
#NSMSPAGE_Action_Input {
  background: #38b6ff;
  border-radius: 30px;
  border: 1px solid white;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  height: 37px;
  color: white;
  padding: 0 20px;
  letter-spacing: 1px;
  outline: 0;
  margin-left: 110px;
}
@media screen and (max-width: 991px) {
  #NSMSPAGE_FORM-CONTAINER {
    flex-direction: column;
    width: 100%;
  }
  #NSMSPAGE_ALL_FORM {
    width: 100%;
    height: auto;
  }
  #NSMSPAGE_FORM_TEXT2 {
    font-size: 28px;
    font-family: "Poppins", sans-serif;
  }
  #NSMSPAGE_SPAN_FORM {
    color: #38b6ff;
  }
  #NSMAPHE_FORM_TEXT3 {
    font-size: 25px;
    letter-spacing: 1px;
  }
}

@media (min-width: 300px) and (max-width: 540px) {
  #NSMSPAGE_FORM {
    width: 100%;
    height: auto;
  }
  #NSMSPAGE_FORM_TEXT2 {
    font-size: 22px;
  }
  #NSMSPAGE_PARAGRAPH_ONE {
    font-size: 10px;
  }
  #NSMSPAGE_Action_Input {
    position: relative;
    left: -90px;
  }
  #LetsBuildPage_span_form {
    font-size: 18px;
  }
  #NSMAPHE_FORM_TEXT3 {
    font-size: 20px;
  }
  #NSMSPAGE_PARAGRAPH_ONE {
    font-size: 10px;
    font-weight: 500;
    width: 90%;
  }
  #NSMSPAGE_CONTACT_BODY {
    background-size: cover;
  }
}
@media (min-width: 600px) and (max-width: 991px) {
  #NSMSPAGE_CONTACT_BODY {
    background-size: cover;
  }
  #NSMSPAGE_FORM_TEXT {
    font-size: 10px;
  }
}