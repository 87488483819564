#LetsBuildPage_contact_body {
  position: relative;
  background: url(/src/Assets/let\ build\ background.png);
  min-height: 100vh;
  padding: 50px 150px;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  flex-direction: column;
  background-attachment: fixed;
}
#LetsBuildPage_Form_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
#LetsBuildPage_Form_Contactinfo {
  width: 90%;
  display: flex;
  flex-direction: column;
}
#LetsBuildPage_Form_Box {
  position: relative;
  padding: 20px 0;
  display: flex;
}
#LetsBuildPage_Form_Text {
  color: whitesmoke;
  position: relative;
  /* left: -210px; */
  margin-left: -120px;
}
#LetsBuildPage_Form_Text2 {
  font-family: "Poppins", sans-serif;
  font-size: 60px;
}
#LetsBuildPage_Form_Text3 {
  font-family: "Poppins", sans-serif;
  font-size: 55px;
}
#LetsBuildPage_span_form {
  font-family: "Poppins", sans-serif;
  color: #38b6ff;
}
#LetsBuildPage_Paragraph1 {
  font-family: "Poppins", sans-serif;
}
#LetsBuildPage_needHelp_form {
  background: transparent;
  width: 500px;
  margin: 5px auto;
  max-width: 95%;
  border-radius: 4px;
  padding: 40px 10px;
}
#LetsBuildPage_Form_Half {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}
#LetsBuildPage_Items_Form {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
#LetsBuildPage_All_form {
  border: 1px solid #386fbb;
  border-bottom-color: #38b6ff;
  padding: 1px 10px;
  /* height: 450px; */
  border-radius: 35px;
  width: 460px;
}
#LetsBuildPage_Form_Title {
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
  letter-spacing: 2px;
  display: inline-block;
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: 600;
}
#LetsBuildPage_Form_InputBox {
  border-radius: 4px;
  border: 1px solid white;
  outline: none;
  padding: 19px;
  width: 100%;
  height: 40px;
  background: whitesmoke;
  font-size: 14px;
}
#LetsBuildPage_Form_Full {
  margin-bottom: 24px;
}
#LetsBuildPage_Textarea_form {
  background: whitesmoke;
  border-radius: 10px;
  outline: none;
  padding: 10px 16px;
  width: 100%;
  height: 110px;
  font-size: 17px;
  /* margin-top: 30px; */
}
#LetsBuildPage_Items_Forms_Select_box {
  width: 100%;
}
#LetsBuildPage_select {
  height: 40px;
  width: 100%;
  padding: 10px 15px;
  line-height: 22;
  outline: none;
  border-radius: 10px;
}
#LetsBuildPage_Action_Input {
  background: #38b6ff;
  border-radius: 30px;
  border: 1px solid white;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  height: 37px;
  color: white;
  padding: 0 20px;
  letter-spacing: 1px;
  outline: 0;
  margin-left: 110px;
}
@media screen and (max-width: 991px) {
  #LetsBuildPage_Form_container {
    flex-direction: column;
    width: 100%;
  }
  #LetsBuildPage_All_form {
    width: 100%;
    height: auto;
  }
  #LetsBuildPage_Form_Text2 {
    font-size: 28px;
    font-family: "Poppins", sans-serif;
  }
  #LetsBuildPage_span_form {
    color: #38b6ff;
  }
  #LetsBuildPage_Form_Text3 {
    font-size: 25px;
    letter-spacing: 1px;
  }
}

@media (min-width: 300px) and (max-width: 540px) {
  #LetsBuildPage_needHelp_form {
    width: 100%;
    height: auto;
  }
  #LetsBuildPage_Form_Text2 {
    font-size: 22px;
  }
  #LetsBuildPage_Paragraph1 {
    font-size: 10px;
  }
  #LetsBuildPage_Action_Input {
    position: relative;
    left: -90px;
  }
  #LetsBuildPage_span_form {
    font-size: 18px;
    position: relative;
    /* left: 80px; */
  }
  #LetsBuildPage_Form_Text3 {
    font-size: 20px;
    position: relative;
    left: 80px;
  }
  #LetsBuildPage_Paragraph1 {
    font-size: 10px;
    font-weight: 500;
    width: 90%;
    position: relative;
    left: 80px;
  }
  #LetsBuildPage_Form_Text2 {
    position: relative;
    left: 80px;
    letter-spacing: 3px;
  }
  #LetsBuildPage_contact_body {
    background-size: cover;
  }
}
@media screen and (max-width: 350px) {
  #LetsBuildPage_Form_Text3 {
    font-size: 15px;
    position: relative;
    left: 70px;
  }
  #LetsBuildPage_Paragraph1 {
    position: relative;
    left: 70px;
  }
  #LetsBuildPage_Form_Text2 {
    position: relative;
    left: 70px;
  }
}
@media screen and (max-width: 330px) {
  #LetsBuildPage_Form_Text3 {
    font-size: 15px;
    position: relative;
    left: 40px;
    /* width: 400px; */
  }
  #LetsBuildPage_Paragraph1 {
    position: relative;
    left: 40px;
    width: 200px;
  }
  #LetsBuildPage_Form_Text2 {
    position: relative;
    left: 40px;
  }
}
@media (min-width: 600px) and (max-width: 991px) {
  #LetsBuildPage_contact_body {
    background-size: cover;
  }
  #LetsBuildPage_Form_Text {
    font-size: 10px;
  }
}
