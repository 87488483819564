#Alllogopage2_content2 {
  color: whitesmoke;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
}
#AlllogoPage2_paragraph1 {
  line-height: normal;
  text-align: justify;
  width: 530px;
  /* padding-bottom: 25px; */
}
#AlllogoPage2_heading1 {
  color: #38b6ff;
  font-size: 30px;
  font-family: "Poppins", sans-serif;
  margin-left: 110px;
  padding: 10px 0px;
  position: relative;
  top: 30px;
}
#AlllogoPage2_row {
  width: 100%;
  max-width: 1170px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow-x: hidden;
  padding: 2rem 3rem;
  grid-gap: 10px;
}
#AlllogoPage2_body {
  display: grid;
  place-items: center;
}
#AlllogoPage2_contentWrapper2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
}
#AlllogoPage2_imgwrapper2 {
  overflow: hidden;
  margin-left: 80px;
}
#SomeSoftwareimage {
  width: 320px;
  position: relative;
  left: -30px;
  top: -10px;
  height: 310px;
  border-radius: 30px;
}
#NewLogo2Id {
  height: 310px;
  width: 300px;
  overflow: hidden;
}
#Alllogopage2_card2 {
  --color: #9747ff;
  position: relative;
  --border-size: 1rem;
  --bg-color: #252525;
  position: absolute;
  width: 340px;
  color: whitesmoke;
  padding: 1rem 2.5rem;
  height: 330px;
  overflow: hidden;
  border-radius: 40px;
  /* box-shadow: 16px 14px 20px #0000008c; */
  isolation: isolate;
}
@media (max-width: 991px) {
  #AlllogoPage2_row {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
}
@media (min-width: 300px) and (max-width: 600px) {
  #Alllogopage2_card2 {
    width: 100%;
    height: auto;
    position: relative;
    /* margin-left: -40px; */
  }
  #NewLogo2Id {
    width: 250px;
    height: 250px;
    position: relative;
    left: -20px;
    /* top: -900px; */
  }
  #AlllogoPage2_imgwrapper2 {
    width: 110%;
    height: auto;
    position: relative;
    order: -1;
    margin-left: -25px;
  }
  #AlllogoPage2_heading1 {
    position: relative;
    margin-left: 30px;
    font-size: 20px;
  }
  #AlllogoPage2_paragraph1 {
    font-size: 20px;
    width: 312px;
    position: relative;
    margin-left: -35px;
    hyphens: none;
    word-spacing: normal;
    hyphens: auto;
  }
}
@media screen and (max-width: 375px) {

  #AlllogoPage2_paragraph1 {
    font-size: 20px;
    width: 250px;
    position: relative;
    margin-left: -45px;
    hyphens: none;
    word-spacing: normal;
    hyphens: auto;
  }
}
@media screen and (max-width: 329px) {
  #AlllogoPage2_imgwrapper2 {
    width: 120%;
    height: auto;
    position: relative;
    order: -1;
    margin-left: -25px;
  }
  #AlllogoPage2_heading {
    position: relative;
    left: 300px;
  }
  #NewLogo2Id {
    height: 240px;
    left: -30px;
  }
 
}
