#NSignInpage_Contact_Main_Body {
  background: url(../../Assets/New\ Assets1.png);
  min-height: 100vh;
  padding: 10px 130px;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  flex-direction: column;
  background-size: cover;
  /* width: 100%; */
  /* background-attachment: fixed; */
  position: relative;
  overflow: hidden;
}
#NSigninpage_Images {
  width: 300px;
  height: 60px;
}
#NSigninpage_text1 {
  color: whitesmoke;
  font-size: 23px;
  letter-spacing: 2px;
  position: relative;
  top: 20px;
  font-family: "Poppins", sans-serif;
}
#NSigninpage_Form_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#NSigninpage_Form_contactinfo {
  width: 80%;
  display: flex;
  flex-direction: column;
}
#Nsigninpage_Form_Title {
  color: whitesmoke;
  font-size: 25px;
  font-family: "Poppins", sans-serif;
  position: relative;
  left: 20px;
  top: -20px;
}
#Nsigninpage_form_title-Two {
  color: whitesmoke;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  position: relative;
  left: 20px;
  font-size: 15px;
}
#Nsigninpage_span_one {
  color: blue;
  font-size: 15px;
}
#Nsigninpage_form {
  background: transparent;
  width: 500px;
  margin: 5px auto;
  max-width: 95%;
  border-radius: 4px;
  padding: 40px 10px;
}
#Nsigninpage_All_form {
  border: 1 px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(3px);
  padding: 1px 10px;
  height: 530px;
  border-radius: 35px;
  width: 460px;
  background: #3a81bf58 60%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
#Nsigninapeg_First_InPut_Box {
  outline: none;
  font-family: "Poppins", sans-serif;
  width: 350px;
  border-radius: 30px;
  color: whitesmoke;
  padding: 0px 20px;
  margin: 20px 10px;
  border: 1px solid whitesmoke;
  height: 48px;
  background: transparent;
}
#Nsigninapge_Second-input-Box {
  outline: none;
  font-family: "Poppins", sans-serif;
  width: 350px;
  border-radius: 30px;
  color: whitesmoke;
  padding: 0px 20px;
  margin: 20px 10px;
  border: 1px solid whitesmoke;
  height: 48px;
  background: transparent;
}
#Nsigninpage_label_One {
  color: whitesmoke;
  position: relative;
  top: 10px;
  left: 20px;
  font-family: "Poppins", sans-serif;
}
#Nsigninpage_label_two {
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
  position: relative;
  margin: 10px 20px;
}
#Nsignipage_or_para {
  font-family: "Poppins", sans-serif;
  color: whitesmoke;
}
#Nsigninpage_Link-forget {
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
  position: relative;
  left: 220px;
  text-decoration: none;
  top: -14px;
  font-size: 14px;
}
#Nsigninapeg_Button_One {
  display: inline-block;
  background-color: #38b6ff;
  padding: 10px 70px;
  position: relative;
  top: -2px;
  left: 100px;
}
#Nsigninpage_or {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 10px;
  top: 10px;
}
#nsigninpahe_hr1 {
  width: 140px;
  height: 1px;
}
#Nsigninpage_hr-two {
  width: 140px;
  height: 1px;
}
#Nsigninpage_social_icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  position: relative;
  top: 20px;
}
#Nsigninpage_icon_Image1 {
  width: 50px;
  height: 50px;
}
@media screen and (max-width: 991px) {
  #NSigninpage_Form_container {
    flex-direction: column;
    width: 100%;
  }
  #Nsigninpage_All_form {
    width: 100%;
  }
  #Nsigninapge_Second-input-Box {
    width: 100%;
  }
  #Nsigninapeg_First_InPut_Box {
    width: 100%;
  }
  #NSigninpage_text1 {
    position: relative;
    top: -3px;
  }
  #NSigninpage_Images {
    width: 350px;
    height: 43px;
    position: relative;
    top: -10px;
  }
  #Nsigninapeg_Button_One {
    position: relative;
    left: 150px;
  }
  #Nsigninpage_Link-forget {
    position: relative;
    left: 300px;
  }
}
@media (max-width: 768px) {
  #Nsigninpage_All_form {
    width: 100%;
    height: auto;
    position: relative;
    top: 10px;
  }
  #Nsigninapge_Second-input-Box {
    width: 100%;
  }
  #Nsigninapeg_First_InPut_Box {
    width: 100%;
  }
  #NSigninpage_text1 {
    position: relative;
    top: -3px;
  }
  #NSigninpage_Images {
    width: 350px;
    height: 43px;
    position: relative;
    top: -10px;
  }
}
@media (min-width: 500px) and (max-width: 715px) {
  #NSigninpage_text1 {
    position: relative;
    top: -3px;
    left: -30px;
    width: 500px;
  }
  #NSigninpage_Images {
    position: relative;
    left: -30px;
  }
}
@media (max-width: 376px) {
  #Nsigninpage_All_form {
    width: 70%;
    height: auto;
    position: relative;
    left: 80px;
  }
  #Nsigninapeg_Button_One {
    position: relative;
    left: 60px;
  }
  #Nsigninpage_Link-forget {
    position: relative;
    left: 200px;
  }
  #NSigninpage_Images {
    position: relative;
    left: -60px;
    width: 250px;
  }
  #NSigninpage_text1 {
    position: relative;
    width: 500px;
    font-size: 15px;
    left: -60px;
  }
}
@media screen and (min-width: 377px) and (max-width: 499px) {
  #Nsigninpage_All_form {
    width: 70%;
    height: auto;
    position: relative;
    left: 80px;
  }
  #Nsigninapeg_Button_One {
    position: relative;
    left: 60px;
  }
  #Nsigninpage_Link-forget {
    position: relative;
    left: 200px;
  }
  #NSigninpage_text1 {
    position: relative;
    width: 500px;
    font-size: 15px;
    left: -60px;
  }
  #NSigninpage_Images {
    position: relative;
    left: -60px;
    width: 250px;
  }
}
