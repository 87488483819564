#alllogopage_heading2 {
  color: #38b6ff;
  font-size: 30px;
  font-family: "Poppins", sans-serif;
  margin-left: 130px;
  padding: 10px 0px;
  position: relative;
  top: 30px;
}

#alllogopage_softwarelogo1 {
  object-fit: cover;
  width: 290px;
  height: auto;
  padding: 20px 10px;
  border-radius: 50px;
}
#alllogopage_softwarelogo2 {
  position: relative;
  top: -200px;
  left: 106px;
  width: 70px;
  height: 50px;
}
#alllogopage_card {
  --color: #9747ff;
  --border-size: 1rem;
  --bg-color: #252525;
  position: relative;
  /* border: 1px solid dodgerblue; */
  width: 300px;
  color: whitesmoke;
  padding: 1rem 0.5rem;
  height: 300px;
  overflow: hidden;
  border-radius: 40px;
  /* box-shadow: 16px 14px 20px #0000008c; */
  isolation: isolate;
}
#NewLogoImage_id {
  height: 250px;
  width: 300px;
}
#alllogopage_card::before,
#alllogopage_card::after {
  content: "";
  position: absolute;
  /* filter: blur(40px); */
}

#alllogopage_bodys {
  display: grid;
  place-items: center;
  width: 100%;
}
#alllogopage_row {
  width: 80%;
  max-width: 1170px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow-x: hidden;
  /* grid-gap: -30px; */
  padding: 2rem 0;
}
#alllogopage_content {
  color: whitesmoke;
}
#alllogopage_imgwrapper {
  overflow: hidden;
}
#alllogopage_contentwrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
}
#alllogopage_paragraph1 {
  font-size: 22px;
  margin-left: -100px;
  line-height: normal;
  text-align: justify;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 991px) {
  #alllogopage_row {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
  #alllogopage_contentwrapper {
    padding-left: 100px;
  }
}
@media (min-width: 300px) and (max-width: 600px) {
  #alllogopage_heading2 {
    /* width: 100%; */
    position: relative;
    margin-left: 60px;
    font-size: 20px;
  }
  #alllogopage_paragraph1 {
    hyphens: none;
    word-spacing: normal;
    hyphens: auto;
  }
  #NewLogoImage_id {
    width: 250px;
  }
}
