#newcareerpage_heading1 {
  color: whitesmoke;
}
#newrcareerpage_hero_image {
  width: 100%;
  position: relative;
  height: auto;
  background: url(../../Assets/carrer-img.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
#newrcareerpage_hero_image::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}
#newrcareerpage_hero_content {
  position: relative;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0 auto;
}
#newrcareerpage_heading1 {
  color: whitesmoke;
  font-size: 35px;
  margin-top: 170px;
  margin-bottom: 10px;
  position: relative;
  margin-left: -170px;
  font-family: "Poppins", sans-serif;
}
#newrcareerpage_paragraph1 {
  color: white;
  font-size: 1.2rem;
  position: relative;
  left: -170px;
  font-family: "Poppins", sans-serif;
}
#newcareerpage_container {
  display: flex;
  margin-top: 100px;
  gap: 20px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
}
#newcareerpage_card {
  background: transparent;
  width: 800px;
  border: 1px solid blue;
  border-radius: 30px;
  height: 190px;
  /* margin: 10px 10px; */
  overflow: hidden;
}
#newcareerpage_link_button {
  text-decoration: none;
  display: inline-block;
  color: white;
  background: #38b6ff;
  text-align: center;
  padding: 10px 30px;
  margin: 10px 30px;
}
#newcareerpage_card_heading1 {
  color: whitesmoke;
  font-size: 30px;
  margin: 10px;
  position: relative;
  right: -23px;
  font-family: "Poppins", sans-serif;
}
#newcareerpage_card_paragraph1 {
  color: whitesmoke;
  position: relative;
  right: -30px;
  font-size: 17px;
  width: 80%;
  font-family: "Poppins", sans-serif;
}
#newcareerpage_card_paragraph3 {
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  margin-left: 30px;
}
#newcareerpage_card:hover {
  transform: translateY(-20px);
}
@media (max-width: 991px) {
  #newcareerpage_container {
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
    margin: 40px 30px 20px 30px;
  }
  #newcareerpage_card {
    flex-direction: column;
    width: 100%;
  }
}
@media (min-width: 300px) and (max-width: 600px) {
  #newcareerpage_card {
    width: 100%;
    height: auto;
    padding: 10px 0px;
  }
  #newcareerpage_container {
    padding: 10px 20px;
  }

  #newrcareerpage_heading1 {
    font-size: 20px;
    position: relative;
    margin-left: -50px;
  }
  #newrcareerpage_paragraph1 {
    position: relative;
    left: -50px;
    font-size: 10px;
  }
  #newrcareerpage_hero_image {
    width: 100%;
    height: 40vh;
    position: relative;
    height: auto;
    background: url(../../Assets/carrer-img.jpeg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  #newrcareerpage_hero_image::after {
    content: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 40vh;
    background: rgba(0, 0, 0, 0.2);
  }
 
}

#newcareerpage_backgrundImage {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url(../../Assets/let\ build\ background.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
#newcareerpage_backgrundImage::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 30%;
  top: 0;
  left: 0;
  /* background-color: rgba(0, 0, 0, 0.44); */
}
#newcareerpage_last_content {
  position: absolute;
  top: 290%;
  left: 50%;
  width: 100%;
  padding: 0 10px;
  transform: translate(-50%, -50%);
  text-align: center;
}
#newcareerpage_last-heading1 {
  color: whitesmoke;
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
  font-family: "Poppins", sans-serif;
}
#newcareerpage_last-span {
  font-family: "Poppins", sans-serif;
  color: #38b6ff;
}
#newcareerpage_last_span2 {
  color: #38b6ff;
  font-family: "Poppins", sans-serif;
}
#newcareerpage_last-button {
  border-radius: 20px;
  border: 1px solid #9747ff;
  background: linear-gradient(
    123deg,
    rgba(58, 129, 191, 0.08) 6.2%,
    rgba(217, 217, 217, 0) 93.68%,
    rgba(65, 48, 90, 0.03) 93.68%
  );
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  padding: 10px 40px;
  position: relative;
  margin-top: 40px;
  flex-shrink: 0;
  font-weight: 600;
  line-height: normal;
}
#newcareerpage_last-button:hover {
  background: #38b6ff;
}
@media (max-width: 600px) {
  #newcareerpage_last_content {
    font-size: 20px;
  }
  #newcareerpage_last-heading1 {
    font-size: 20px;
    position: relative;
    top: 30px;
  }
}

@media (min-width: 300px) and (max-width: 599px) {
  #newcareerpage_last_content {
    position: absolute;
    top: 360%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  #newcareerpage_last-button {
    position: relative;
    top: 30px;
  }
}
