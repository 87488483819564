#AlllogoPage3_body3 {
  display: grid;
  place-items: center;
  font-family: "Poppins", sans-serif;
}
#AlllogoPage3_row3 {
  width: 85%;
  max-width: 1170px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow-x: hidden;
  grid-gap: 70px;
  padding: 2rem 0;
}
#Alllogopage3_paragraph3 {
  color: whitesmoke;
  font-size: 22.5px;
  font-weight: 500;
  text-align: justify;
  width: 600px;
  line-height: normal;
  position: relative;
  left: -10px;
  font-family: "Poppins", sans-serif;
}
#Alllogopage3_heading1 {
  color: #38b6ff;
  font-size: 35px;
  font-weight: 600;
  margin-left: 100px;
  padding: 10px 10px;
  position: relative;
  font-family: "Poppins", sans-serif;
  top: 60px;
}
#AlllogoPage3_imgwrapper3 {
  overflow: hidden;
}
#Alllogopage3_contentWrapper3 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
}
#AlllogoPage3_card3 {
  position: relative;
  width: 390px;
  color: whitesmoke;
  padding: 2rem 1rem;
  height: 330px;
  overflow: hidden;
  border-radius: 30px;
  /* box-shadow: 16px 14px 20px #0000008c; */
  isolation: isolate;
}
#NewLogoImage3Id {
  width: 330px;
  height: 300px;
}
@media (max-width: 991px) {
  #AlllogoPage3_row3 {
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }
}
@media (min-width: 330px) and (max-width: 600px) {
  #AlllogoPage3_imgwrapper3 {
    width: 96%;
    height: auto;
    position: relative;
    /* margin-left: 10px; */
  }
  #NewLogoImage3Id {
    width: 250px;
    height: 250px;
    position: relative;
    /* left: -30px; */
  }
  #Alllogopage3_heading1 {
    position: relative;
    left: -55px;
    font-size: 20px;
  }
  #Alllogopage3_paragraph3 {
    position: relative;
    left: 2px;
    width: 100%;
    /* text-align: justify; */
    hyphens: none;
    word-spacing: normal;
    hyphens: auto;
  }
}
@media screen and (max-width: 329px) {
  #AlllogoPage3_imgwrapper3 {
    width: 100%;
    height: auto;
    position: relative;
    left: -10px;
  }
  #NewLogoImage3Id {
    height: 245px;
    width: 250px;
    overflow: hidden;
  }
  #Alllogopage3_heading1 {
    font-size: 21px;
    position: relative;
    left: -50px;
  }
  #Alllogopage3_paragraph3 {
    position: relative;
    left: 2px;
    width: 240px !important;
    /* width: 100%; */
    /* text-align: justify; */
    hyphens: none;
    word-spacing: normal;
    hyphens: auto;
  }
}
