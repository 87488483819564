.Whatsapppage_main_body{
  word-spacing: normal;
}

#whatsapppage_bgImage {
  position: relative;
  background-image: url("../../Assets/mw.jpeg");
  width: 100%;
  height: 600px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

#whatsapppage_bgImage::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* background-color: rgba(198, 48, 48, 0.55); */
}

#whatsapppage_content {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  margin-left: -25rem;
  width: 100%;
}

#whatsapppage_heading1 {
  font-size: 4rem;
  text-align: center;
  color: #fff;
  font-weight: 900;
  font-family: "Poppins", sans-serif;
}

#whatsapppage_paragraph1 {
  font-size: 1.7em;
  padding: 10px;
  padding-left: 1.5rem;
  color: #fff;
  margin: 0  27rem;
  font-family: "Poppins", sans-serif;
}
#whatsapppage_card_container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px;
}
#whatsapppage_card {
  width: 250px;
  border-radius: 20px;
  overflow: hidden;
  margin: 20px;
  /* border: 1px dotted blueviolet; */
  transition: transform all ease-in;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
#whatsapppage_card:hover {
  transform: translateY(-20px);
}
#whatsapppage_image1 {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 20px;
}
#whatsapppage_card_content {
  padding: 16px;
}
#whatsapppage_button {
  display: inline-block;
  text-decoration: none;
  padding: 8px 64px;
  background: transparent;
  border-radius: 20px;
  color: whitesmoke;
  border: 2px solid transparent;
  background: linear-gradient(#212121, #212121) padding-box,
              linear-gradient(-120deg, transparent 0%,#382659, #0d5477) border-box;
  /* margin-left: 50px; */
  font-family: "Poppins", sans-serif;
  font-size: 20px;
}
#whatsapppage_button:hover {
  background:#38b5fe;
}
#whatsapppage_bulk_heading2 {
  color: whitesmoke;
  font-size: 40px;
  font-weight: 500;
  padding: 30px 0px;
  font-family: "Poppins", sans-serif;
  text-align: center;
}
#whatsapppage_heading3 {
  color: whitesmoke;
  font-size: 40px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  text-align: center;
}
#whatsapppage_paragraph-all-body {
  padding: 75px 80px;
}
#whatsapppage_paragraphs1,
#whatsapppage_paragraphs2,
#whatsapppage_paragraphs3 {
  color: whitesmoke;
  font-size: 28px;
  line-height: 45px;
  padding: 15px 0px;
  font-family: "Poppins", sans-serif;
}

#whatsapppage_heading4 {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 600;
  padding-top: 3rem;
  color: whitesmoke;
}
@media (max-width: 768px) {
  #whatsapppage_heading1 {
    font-size: 2rem; 
    padding: 0;
    top: 0%;
    left: 0;
    right: 0;
    margin-left: 28rem;
    text-align: center;
  }
  #whatsapppage_paragraph1 {
    font-size: 1.4em; 
    
  }
  #whatsapppage_heading4 {
    font-size: 40px;
    text-align: center;
  }
  #whatsapppage_bgImage{
    align-items: center;
  }
  #whatsapppage_paragraphs1,
  #whatsapppage_paragraphs2,
  #whatsapppage_paragraphs3 {
    font-size: 20px;
    line-height: 32px;
    padding-top:5px;
    text-align: justify;
    text-align-last: justify;
    word-spacing: -1px;
  }
  #whatsapppage_paragraph-all-body {
    padding:4rem 40px;
    margin: 0;
    text-align: justify;
    /* word-spacing: normal; */
    /* text-align-last:justify; */
  }
}
@media (max-width: 400px) {
  #whatsapppage_paragraph1 {
    font-size: 1em; 
    width: 221px;
    
  }
  #whatsapppage_heading1{
    font-size: 2em; 
    width: 221px;

  }
}