
/* .home_main{
  width: 110%;
} */
.Homepage_container {
  /* top: 50%; */
  margin-top: -8rem;
  position: relative;
  width: 100%;
  font-family: Poppins;
  /* overflow: hidden; */
  height: 90vh; /* Adjust the height as needed */
  background: url("/src//Assets/homemw.gif") center/cover no-repeat;
}
.home_main h3{
   margin-top:8rem;
}
.Homepage_content {
  position: relative;
  text-align: center;
  align-self: center;
  top: 250px;
  color: #fff;
  font-family: 'Poppins', sans-serif;
}
.Homepage_span {
  position: relative;
  left: 10px;
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
  color: #38b6ff;
}
.Homepage_heading1 {
  font-size: 45px;
  /* margin-bottom: 10px; */
  font-family: "Poppins", sans-serif;
}
.Homepage_heading2 {
  font-size: 2.2rem;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
}
.Homepage_button {
  width: 10rem; /* Set your desired width */
  height: 3rem; /* Set your desired height */
  border-radius: 5rem; /* Make it round */
  background-color: #38b6ff;
  border: 1px solid transparent;
  color: white;
  font-weight: 1000;
  font-size: 1.1rem;
  cursor: pointer;
  outline: none;
}
.Homepage_button:hover {
  background-color:rgb(51, 193, 51);
}
.Homepage_paragraph1 {
  font-size: 1.2rem;
  line-height: 1.6;
  /* padding: 10px 270px; */
  color: #fff; /* Set your desired text color */
  margin: 0px 160px 30px 160px; /* Add spacing at the bottom */
  font-family: "Poppins", sans-serif;

}

.content p::first-letter {
  font-size: 1.5rem; /* Increase the font size of the first letter for emphasis */
  font-weight: bold; /* Make the first letter bold */
}

.content p a {
  color: #007bff; /* Set link color */
  text-decoration: underline; /* Underline links */
}
.contact-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 20%;
  left: 0;
  width: 50%;
  height: 80vh;
  z-index: 1000;
  margin-left: 22rem;
  padding: 0 15px; 
  box-sizing: border-box; 
}

.contact-form {
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 400px; 
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
  position: relative;
  color: white;
  box-shadow: 0 15px 25px rgba(97, 97, 97, 0.122);
    border: 1px solid #9747ff;
    background: linear-gradient(123deg, rgba(58, 129, 191, 0.7) 6.2%, rgba(65, 48, 90, 0.7) 93.68%);
    background-color: transparent;
}

.contact-form h2 {
  margin-bottom: 20px;
}

.contact-form p {
  margin-bottom: 20px;
  color: #f9d835;
}

.contact-form label {
  display: block;
  margin-bottom: 5px;
}

.contact-form input,
.contact-form select {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: #cff0ff 0px 7px 7px -5px;
  border-inline: 2px solid transparent;
}

  .contact-form .input::-moz-placeholder {
    color: rgb(170, 170, 170);
  }
  
  .contact-form .input::placeholder {
    color: rgb(170, 170, 170);
  }
  
  .contact-form .input:focus {
    outline: none;
    border-inline: 2px solid #12B1D1;
  }
  /*  */

  .btn {
     margin: 15px;
    align-items: center;
    position: relative;
    justify-content: center;
    font-size: 17px;
    left: 27%;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    padding: 0.4em 2em;
    display: inline-block;
    border-radius: 6em;
    transition: all .2s;
    border: none;
    font-family: inherit;
    font-weight: 500;
    color: black;
    background-color: white;
   }
   
   .btn:hover {
    background-color:rgba(37, 216, 37, 0.871) ;
    color: #fff;
    cursor: pointer;
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
   }
   
   .btn:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
   }
   
   .btn::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .4s;
   }
   
   .btn::after {
    background-color: #fff;
   }
   
   .btn:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
   }
   textarea {
    width: 100%;
    padding: 20px 16px;
    border-radius: 8px;
    resize: none;
    color: #fff;
    height: 96px;
    border: 1px solid #414141;
    background-color: #FFf;
    font-family: inherit;
  }


/*  */
.contact-form button:nth-child(1),
.contact-form .close-btn {
  /* background-color: yellowgreen;
  color: #fff; */
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background: #1e1e1e;
  cursor: pointer;
  color: greenyellow;
  box-shadow: 0 0 10px #363636, inset 0 0 10px #363636;
}

.contact-form .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
@media (max-width: 768px) {
  .Homepage_paragraph1 {
    font-size: 1.2rem;
    line-height: 1.6;
    /* padding: 10px 270px; */
    color: #fff; /* Set your desired text color */
    margin: 0px 100px 30px 100px; /* Add spacing at the bottom */
    font-family: "Poppins", sans-serif;
  
  }
 .Homepage_content{
  text-align: center;
   top: 20%;
 }
 .Homepage_container{
  height: 100vh;
  padding-bottom: 10rem;
 }
  .Homepage_button {
    width: 30%; /* Make the button full width on small screens */
    top: 10%;
    margin-top: -18rem;
  }
  .Homepage_heading1{
    margin-top: -10rem;
  }
  .contact-form-container {
    width: 100%; /* Make the form container full width on small screens */
    margin-left: 0; /* Remove margin for small screens */
  }
}
@media (max-width: 400px) {
  .Homepage_paragraph1 {
    font-size: 15px;
    line-height: 1.6;
    /* padding: 10px 270px; */
    color: #fff; /* Set your desired text color */
    margin: 0px 40px 30px 40px; /* Add spacing at the bottom */
    font-family: "Poppins", sans-serif;
    /* padding: 10px; */
  }
  .home_main h3{
    margin-top:12rem;
 }
 .Homepage_content{
  text-align: center;
   top: 20%;
 }
 .Homepage_container{
  height: 65vh;
  /* padding-bottom: 10rem; */
 }
  .Homepage_button {
    width: 30%; /* Make the button full width on small screens */
    top: 10%;
    margin-top: -18rem;
  }
  .Homepage_heading1{
    margin-top: 12rem;
    font-size: 25px;
    white-space: nowrap;
  }
  .Homepage_heading2{
    /* margin-top: 2rem; */
    font-size: 18px;
  }
  .Homepage_span{
    font-size: 25px;
  }
  .contact-form-container {
    width: 100%; /* Make the form container full width on small screens */
    margin-left: 0; /* Remove margin for small screens */
  }
}
