.footer {
  background-color: #121212;
  margin: 0;
  padding: 20px 0px;
  width: 100vw;
  height: 30vh;
  font-family: "poppins",sans-serif;
}
.social-links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
#foot-p{
  padding: 0.5rem;
  font-size: 0.9em;
}
.news-sec{
  padding: 0.5rem;
}
.social-links a {
  text-decoration: none;
  color: #333; 
  border-radius: 50%;
  background-color: #0c0c0ca5;
  color: #fff;
  padding: 10px;
  margin: 5px;
  display: inline-flex;
  align-items: center;
  font-family: serif;
  font-weight: 0;
  justify-content: center;
  transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out;
}
.social-links{
  padding-top: 13px;
}
.social-links a:hover {
  background-color: #161616; /* Change the hover background color as needed */
  transform: scale(1.1); /* Adjust the scale factor as needed */
}

.phn {
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  color: whitesmoke;
}
.first {
  text-align: center;
  color: whitesmoke;
}
.first p {
  font-size: 1.2rem;
}
.phn p,
.phn span {
  margin-left: -9rem;
  /* margin-left: 0; */
  text-align: center;
  color: whitesmoke;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: normal;
}
.sp {
  margin-left: -7rem;
  font-size: 1.4em;
  text-align: center;
}
.sp span{
  margin-right: 3rem;
}
.call {
  margin: 1rem;
  font-size: 1.7em;
  text-align: center;
}
.footer-section,
.first,
.About,
.services,
.news p,
span {
  color: beige;
}
.About a,
.services a {
  text-decoration: none;
  font-size: 1.2em;
  color: white;
  line-height: 2.3em;
}
.phn{
  margin: 0;
  margin-right: 0rem;
}
.head {
  text-decoration: underline;
  font-size: 1.6em;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
}
.footer-sec {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.footer-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 7rem;
}
.footer-section a {
  color: #fff;
  text-decoration: none;
}

input[type="button"] {
  background-color: #38b6ff;
  color: white;
  padding: 10.5px 20px;
  border: none; /* No border */
  border-radius: 1px;
  cursor: pointer;
  font-size: 16px; /* Font size */
  transition: background-color 0.3s;
}

input[type="button"]:hover {
  background-color: #45a049;
}
input[type="email"] {
  width: 70%; /* Full width of the container */
  padding: 10px; /* Padding for better appearance */
  margin-bottom: 10px; /* Margin for spacing between elements */
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 16px;
}

input[type="email"]::placeholder {
  color: #999;
}

@media screen and (max-width: 400px) {
  .footer-section{
    padding: 25px !important;
  }
}
@media screen and (max-width: 768px) {
  .social-links {
    flex-wrap: wrap;
    align-items: center;
  }
   .footer{
    height: 50vh;
   }
  .phn{
    /* flex-wrap: wrap; */
    text-align: center;
  }
  .sp span{
    text-align: center;
    /* margin-left: 9rem; */
    padding: 0;
    margin: 0;
  }
  .About,.services{
    margin: 2rem;
  }
  .call{
    display: none;
  }
  .news{
    text-align: center;
    align-items: center;
  }
  .footer-section {
    padding:0; 
  }
}
