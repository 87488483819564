/* Navbar styling */
* {
  margin: 0;
  padding: 0;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed; 
  top: 0;
  left: 0;
  right: 0;
  padding: 0rem 6rem;
  background-color: transparent; 
  color: #fff;
  backdrop-filter: blur(5px);
  height: 6rem;
  width: 100%;
  margin: 0 auto;
  font-family: "poppins", sans-serif;
  z-index: 1000; 
  border-bottom: 1px solid #d9d7d750;
}
.navbar.scrolled {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.logo {
  object-fit: cover;
  max-width: 250px;
  cursor: pointer;
}
/* Desktop menu styling */
.desktopMenu {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  padding: 0px 10px;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
}
.desktopMenu a:hover{
  color: #007bff;
}
.desktopMenuListItem {
  color: #fff;
  text-decoration: none;
  margin-right: 20px;
  position: relative;
  padding: 0px 20px;
  justify-content: space-between;
  white-space: nowrap;
}
/* .desktopMenuListItem:hover{
  color: #007bff;
  padding-bottom: 0.1rem;
  border-bottom: 4px solid #007bff;
} */
/* Add padding to mobile menu items when hovered */
.sign {
  background: #38b6ff;
  height: 2.8rem;
  width: 7rem;
  border-radius: 2rem;
  font-style: normal;
  font-size: medium;
  font-weight: 600;
  color: #fff;
  border: none;
}
.mobMenu {
  display: none;
  cursor: pointer;
  height: 2.5rem;
  margin-right: 0.4rem;
  width: 4rem;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown-content a:hover{
  color: #0082e6;
}
/* Mobile menu styling */
.dropdown {
  position: relative;
}

.dropbtn {
  color: #fff;
  text-decoration: none;
  margin-right: 20px;
  cursor: pointer;
}

.dropdown-content {
  /* display: none; */
  position: absolute;
  background-color: #121212;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  /* margin:0rem; */
  width: 15rem;
  border-radius: 10px;
  right: -10%;
  margin-top: 25%;
}
.dropdown-content span{
  color: #fff;
}
.dropdown-content a {
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
/* ?????? */
.dropdownItem {
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  
}
.arrow-icon{
  font-size: 0.9rem;
}
.dropdownItem:hover .arrow-icon {
  visibility: visible;
  transform: translateX(1px); 
  transition: transform 0.3s ease; 
}

.dropdownItem:hover,.dropdownItem span:hover {
  transform: translateX(15px); 
  transition: transform 0.3s ease; 
  color: #007bff;
}

.dropdownItem .arrow-icon {
  visibility: hidden;
  margin-top: 20px;
}


.dropdownItem span {
  transition: transform 0.3s ease; 
}
.close-btn {
  position: absolute;
  top: -20px !important;
  /* right: 10px !important; */
  float: right !important;
}
/* //////// */
.navMenu {
  display: none;
  flex-direction: column;
  background-color: #333;
  padding: 15px;
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
}
.mobMenu {
  display: none;
  /* height: 40px;
  width: 40px;
  color: #007bff; */
}

.listItem {
  color: #fff;
  text-decoration: none;
  margin-bottom: 10px;
  white-space: nowrap;
}
/* Add this CSS to your existing styles or create a new CSS file */

.sign-in-form {
  position: fixed;
  top: 400%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #33f50cd7;
  padding: 20px 4rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.sign-in-form label {
  display: block;
  margin-bottom: 8px;
}

.sign-in-form input {
  width: 100%;
  padding: 8px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.sign-in-form button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 3rem;
}
.desktopMenuListItem.active {
  color: #007bff; 
  font-weight: bold; 
  padding-bottom: 0.01rem;
  border-bottom: 4px solid #007bff;
}

/* Style for the dropdown items */
.dropdownItem.active {
  background-color: #007bff; 
  padding-bottom: 0.01rem;
  border-bottom: 4px solid #007bff;
  color: #ffffff;
  font-weight: bold;
}
/* Media query for mobile responsiveness */
@media (min-width: 1050px) {
  .mobMenu {
    display: none !important;
    height: 40px;
    width: 40px;
  }




}
@media (max-width: 1050px) {
  .navbar {
    padding: 0rem 2rem; 
  }
  .desktopMenu {
    display: none;
    position: relative;
    left: 200px;
  }

  .mobMenu {
    display: block;
    height: 40px;
    width: 40px;
    color: #007bff;
  }

  .navMenu {
    display: flex;
    flex-direction: column;
    background-color: #333;
    padding: 15px;
    position: absolute;
    top: 70px;
    left: 0;
    /* width: 80%; */
    z-index: 1;
  }
  .logo{
    max-width: 30%;
  }
  .listItem {
    color: #fff;
    text-decoration: none;
    margin-bottom: 10px;
  }

  /* Dropdown styles for mobile */
  .dropdown-content {
    display: flex;
    flex-direction: column;
    position: static;
    box-shadow: none;
    width: 100%;
  }

  .dropdown:hover .dropdown-content {
    display: flex;
  }
  .sign-in-form {
    width: 50%; /* Adjust the width for larger screens */
  }
  .contact_us_card{
    top: 55%;
    left: 50%;
    /* borderRadius: 5%; */
    backdrop-filter: blur(0px);
    padding: 40px;
    /* height: 500px; */
    max-width: 520px;
    width: 100%;
    box-shadow: 0 15px 25px rgba(97, 97, 97, 0.122);
    border: 1px solid #9747ff;
    background: linear-gradient(123deg, rgba(58, 129, 191, 0.7) 6.2%, rgba(65, 48, 90, 0.7) 93.68%);
    overflow:scroll;
    background-color: transparent;
  }
}
.contact_us_card{
  top: 55%;
  left: 50%;
  /* borderRadius: 5%; */
  backdrop-filter: blur(0px);
  padding: 40px;
  height: 600px;
  max-width: 520px;
  width: 100%;
  box-shadow: 0 15px 25px rgba(97, 97, 97, 0.122);
  border: 1px solid #9747ff;
  background: linear-gradient(123deg, rgba(58, 129, 191, 0.7) 6.2%, rgba(65, 48, 90, 0.7) 93.68%);
  /* overflow: hidden; */
  background-color: transparent;
}

