.scr{
    position: relative;
    background: url(/src/Assets/let\ build\ background.png);
    min-height: 10vh;
    padding: 50px 150px;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    flex-direction: column;
    z-index: 0;
    /* opacity: 1; */
}