#newroompage_hero_image {
  position: relative;
  width: 100%;
  height: auto;
  text-align: center;
  background: url("../../Assets/newsroom-img.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#newroompage_hero_image::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
#newroompage_hero_content {
  position: relative;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  left: 5%;
}
#newroompage_heading1 {
  color: whitesmoke;
  font-size: 50px;
  margin-top: 30px;
  letter-spacing: 2px;
  margin-bottom: 30px;
  position: relative;
  margin-left: -600px;
  font-family: "Poppins", sans-serif;
}
#newroompage_paragraph1 {
  color: whitesmoke;
  font-size: 25px;
  /* margin-bottom: 10px; */
  position: relative;
  font-family: "Poppins", sans-serif;
}

#newroompage_paragraphall_body {
  padding: 20px 70px;
}
#newroompage_paragraph2 {
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
  font-size: 1.3rem;
}
#newroompage_heading2 {
  color: #38b6ff;
  position: relative;
  margin-top: 30px;
  font-family: "Poppins", sans-serif;
  font-size: 1.9rem;
}
#newroompage_paragraph3 {
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
  font-size: 1.3rem;
}
#newroompage_heading3 {
  color: #38b6ff;
  position: relative;
  margin-top: 30px;
  font-family: "Poppins", sans-serif;
  font-size: 1.9rem;
}
#newroompage_paragraph4 {
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
  font-size: 1.3rem;
}
#newroompage_heading4 {
  color: #38b6ff;
  position: relative;
  margin-top: 30px;
  font-family: "Poppins", sans-serif;
  font-size: 1.9rem;
}
#newroompage_paragraph5 {
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
  font-size: 1.3rem;
}
#newroompage_heading5 {
  color: #38b6ff;
  position: relative;
  margin-top: 30px;
  font-family: "Poppins", sans-serif;
  font-size: 1.9rem;
}
#newroompage_paragraph6 {
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
  font-size: 1.3rem;
}
#newroompage_heading5 {
  color: #38b6ff;
  position: relative;
  margin-top: 30px;
  font-family: "Poppins", sans-serif;
  font-size: 1.9rem;
}
#newroompage_paragraph7 {
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
  font-size: 1.3rem;
}
#newroompage_heading6 {
  color: #38b6ff;
  position: relative;
  margin-top: 30px;
  font-family: "Poppins", sans-serif;
  font-size: 1.9rem;
}
#newroompage_paragraph8 {
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
  font-size: 1.3rem;
}
#newroompage_paragraph9 {
  color: whitesmoke;
  position: relative;
  margin-top: 30px;
  font-family: "Poppins", sans-serif;
  font-size: 1.3rem;
}
#newroompage_heading7 {
  color: #38b6ff;
  position: relative;
  margin-top: 30px;
  font-family: "Poppins", sans-serif;
  font-size: 1.9rem;
}
@media (max-width: 768px) {
  #newroompage_hero_content {
    width: 100%;
  }
  #newroompage_heading1 {
    font-size: 30px;
    position: relative;
    margin-left: 30px;
  }
  #newroompage_paragraph1 {
    font-size: 20px;
    position: relative;
    margin-left: 30px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  #newroompage_heading1 {
    font-size: 50px;
    position: relative;
    margin-left: 20px;
    margin-top: 30px;
  }
  #newroompage_hero_content {
    width: 100%;
  }
  #newroompage_paragraph1 {
    font-size: 20px;
    position: relative;
    margin-left: 30px;
  }
}
