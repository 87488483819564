.servicepage_container {
  min-height: 100vh;
  width: 100%;
}
.servicepage_wrapper {
  padding: 5% 8%;
}
.serviceapge_paragraph2 {
  font-size: 13px;
  padding: 10px 0px 10px 6px;
  font-family: "Poppins", sans-serif;
  color: whitesmoke;
  width: 100%;
  font-weight: 500;
  margin: 1px 0;
}
.servicepage_service {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.servicepage_heading2 {
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
  position: relative;
  top: 6px;
  left: -10px;
}
.serviceapage_card:hover{
   background-image: linear-gradient(#212121, #212121) padding-box,
  linear-gradient(-120deg, transparent 0%,#382659, #0d5477) border-box; 
}
.servicepage_wrapper h4:hover{
  color: #0d5477;
}
.servicepage_card_icon2:hover,.servicepage_card_icon1:hover{
   background-color: #0d5477;
   border-radius: 5px;
}
.servicepage_cards img:hover{
  background-color: #0d5477;
  color: #0d5477;
  border-radius: 10px;
}
.servicepage_cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 80px;
  margin-top: 20px;
}
.serviceapage_card{
  height: 300px;
  width: 300px;
  padding: 3% 8%;
  /* border: 2px solid rgba(114, 226, 174, 0.2); */
  border-radius: 64px;
  transition: 0.6s;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.serviceapage_card:hover{
  background-color: #0d5477;
}
.serviceapage_card::after {
  content: "";
  position: absolute;
  top: 150%;
  left: -200px;
  width: 120%;
  transform: rotate(50deg);
  /* background-color: #ffff; */
  background-color: #0d5477;
  height: 18px;
  filter: blur(30px);
  opacity: 0.5;
  transition: 1s;
}
.serviceapage_card:hover::after {
  width: 225%;
  top: -100%;
}
.serviceapage_card-image {
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 3rem;
  border: 2px dotted red;
}
.serviceapage_card {
  background-color: transparent;
  transform: translateY(-10px);
  border-color: #00ff37;
  border-radius: 60px;
  /* border: 2px solid #9747ff; */
  border: 3px solid transparent;
  background: linear-gradient(#212121, #212121) padding-box,
              linear-gradient(-120deg, transparent 0%,#382659, #0d5477) border-box;
}
.serviceapage_card:hover {
  transform: translateY(-40px);
  overflow: hidden;
}
.servicepage_card_icon1 {
  font-size: 70px;
  color: white;
  padding: 10px;
  /* border: 1px solid whitesmoke; */
}

.servicepage_card_icon2 {
  font-size: 50px;
  color: white;
  padding: 30px;
}
.servicepagea_cardy_icon {
  font-size: 30px;
  padding: 30px;
}
.servicepage_cardY-icon2 {
  font-size: 7px;
  padding: 30px;
}
.servicepage_card_iconsms {
  font-size: 7px;
  padding: 30px;
}
.servicepage_card_iconht {
  font-size: 7px;
  padding: 30px;
}
.servicepagea_cardy_imageall1 {
  font-size: 50px;
  color: white;
  padding: 20px;
  /* border: 1px solid white; */
  background: transparent;
}
.servicepage_paragraph1 {
  text-align: center;
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  padding: 2px 280px;
}
.servicepage-span {
  text-align: center;
  color: #38b6ff;
  font-family: "Poppins", sans-serif;
}
.servicepage_heading1 {
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: whitesmoke;
  font-size: 3rem;
  margin-top: 60px;
}
.servicepage_heading3 {
  text-align: center;
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
}
.serviceapge_paragraph2 {
  color: whitesmoke;
  text-align: center;
  font-size: 13px;
}
.servicepage_heading6 {
  font-family: "Poppins", sans-serif;
  color: whitesmoke;
  /* padding: 0px 10px; */
  font-size: 13px;
}
.serviceapge_paragraph6 {
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  padding: 10px 10px;
}
.servicepage_heading7 {
  color: whitesmoke;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
}
.serviceapge_paragraph7 {
  color: whitesmoke;
  padding: 5px;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
}
.servicepage_heading5 {
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
  padding: 10px 10px;
}
.serviceapge_paragraph5 {
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
  font-size: 12.5px;
  padding: 0px 10px 10px 10px ;
}
.servicepage_heading4 {
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
}
.serviceapge_paragraph4 {
  font-family: "Poppins", sans-serif;
  color: whitesmoke;
  font-size: 12px;
  padding: 10px 0px;
}
.servicepage_heading3 {
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
  padding: 10px 0px;
}
.serviceapge_paragraph3 {
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
  padding: 10px 0px;
  font-size: 13px;
}

@media screen and (max-width: 1200px) {
  .servicepage_cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 900px) {
  .servicepage_cards {
    grid-template-columns: repeat(1, 1fr);
  }
  
}
@media screen and (max-width: 768px) {
  .servicepage_paragraph1 {
    font-size: 15px; /* Adjust the font size for smaller screens */
    padding:0; /* Adjust the padding for smaller screens */
  }
}
@media screen and (max-width: 400px) {
  .servicepage_heading1 {
    font-size: 28px; /* Adjust the font size for smaller screens */
    padding:1; /* Adjust the padding for smaller screens */
  }
  .servicepage_paragraph1{
padding: 10px;
  }
}
