.team_container {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url("/src/Assets/team-main.jpeg") center/cover no-repeat;
  overflow: hidden;
  backdrop-filter: blur(50px);
  height: 500px;
  font-family: "Poppins", sans-serif;
}

.team_content {
  color: #fff;
  backdrop-filter: blur(2px);
}

.team_heading1 {
  font-size: 3.2rem;
  position: relative;
   margin-top: 13rem;
   text-align: center;
  letter-spacing: 2px;
    font-weight: 1000;
  font-family: "Poppins", sans-serif;
}
.Termspage_body h2{
  color: #38b5fe;
}
.termpage_all_content {
  color: whitesmoke;
  margin-top: 40px;
  font-family: "Poppins", sans-serif;
}
.termpage_heading1 {
  font-size: 40px;
  margin-left: 50px;
  padding: 30px 0px;
}
.termpage_heading2 {
  font-size: 1.9rem;
  margin-left: 50px;
}
.termpage_paragraph1 {
  font-size: 1.2rem;
  padding: 10px 50px;
}
.termpage_heading3 {
  font-size: 1.9rem;
  margin-left: 50px;
}
.termpage_paragraph2 {
  font-size: 1.2rem;
  padding: 10px 50px;
}
.termpage_heading4 {
  font-size: 1.9rem;
  margin-left: 50px;
}
.termpage_paragraph3 {
  font-size: 1.2rem;
  padding: 10px 50px;
}
.termpage_heading5 {
  font-size: 1.9rem;
  margin-left: 50px;
}
.termpage_paragraph4 {
  font-size: 1.2rem;
  padding: 10px 50px;
}
.termpage_heading6 {
  font-size: 1.9rem;
  margin-left: 50px;
}
.termpage_paragraph5 {
  font-size: 1.2rem;
  padding: 10px 50px;
}
.termpage_heading7 {
  font-size: 1.9rem;
  margin-left: 50px;
}
.termpage_paragraph6 {
  font-size: 1.2rem;
  padding: 10px 50px;
}
.termpage_heading8 {
  font-size: 1.9rem;
  margin-left: 50px;
}
.termpage_paragraph7 {
  font-size: 1.2rem;
  padding: 10px 50px;
}
.promotionpage_option{
     color: black;
}
