section {
  background-color: #121212;
  color: white;
  /* padding-bottom: 200px; */
}
.faq-accordion {
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  z-index: 2;
}

.faq-item {
  margin-bottom: 15px;
}

.faq-question {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  /* #dad6d6 */
  border-radius: 1px;
  transition: background-color 0.3s ease;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
}
.ques {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2rem;
  font-weight: 900;
  margin: 4rem;
  margin-left: 0rem;
}
.faq-question h3 {
  color: black;
  margin: 2px;
}

.faq-answer {
  display: none;
  /* padding: 35px; */
  padding: 1.5rem;
  border-bottom: 2px solid #38b6ff;
  background-color: #fff;
  color: black;
  font-size: 0.2rem;
  left: 60%;
}
span {
  color: black;
  height: 20px;
  font-weight: 1000;
  margin-right: 1rem;
}
.faq-question.active {
  background-color: #38b6ff;
  /* Light #e0e0e0 gray background for active state */
}

.faq-answer.active {
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
  font-weight: 700;
}
