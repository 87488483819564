.privacy_container {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url("/src/Assets/priv-main.jpeg") center/cover no-repeat;
  overflow: hidden;
  /* backdrop-filter: blur(5px); */
  height: 500px;
  font-family: "Poppins", sans-serif;
}

.privacy_content {
  color: #fff;
  /* backdrop-filter: blur(5px); */
}

.privacy_heading1{
  font-size: 65px;
  position: relative;
   margin-top: 10rem;
  letter-spacing: 2px;
  margin-left: 4rem;
  font-weight: 900;
  font-family: "Poppins", sans-serif;
}
.PrivacyPage_body h2{
  color: #38b5fe;
}
.privacy_paragraph1 {
  font-size: 1.7em;
  padding: 10px 4rem;
  line-height: 1.5;
  font-family: "Poppins", sans-serif;
}
.Privacypage_all_paragraph {
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
}
.Privacypage_heading1 {
  color: whitesmoke;
}
.PrivacyPage_heading {
  font-size: 32px;
  text-align: center;
  font-family: "Poppins", sans-serif;
}
.Privacypage_paragraph1 {
  font-size: 1.5rem;
  padding: 20px 50px;
  font-family: "Poppins", sans-serif;
}
.Privacypage_heading2 {
  font-family: "Poppins", sans-serif;
  font-size: 1.9rem;
  margin-left: 50px;
}
.Privacypage_paragraph2 {
  font-size: 1.2rem;
}
.Privacypage_paragraph2 {
  font-size: 1.2rem;
  padding: 0px 50px;
}
.Privacypage_ul {
  font-size: 1.2rem;
  cursor: pointer;
  font-weight: 400;
  padding: 10px 90px;
}
.Privacypage_li {
  font-size: 1.2rem;
  cursor: pointer;
}
.Privacypage_heading3 {
  font-size: 1.9rem;
  margin-left: 50px;
}
.Privacypage_paragraph3 {
  font-size: 1.2rem;
  padding: 0px 50px;
}
.Privacypage_ul2 {
  cursor: pointer;
  padding: 10px 90px;
}
.Privacypage_li2 {
  font-size: 1.2rem;
}
.Privacypage_heading4 {
  font-size: 1.9rem;
  margin-left: 50px;
}
.Privacypage_paragraph4 {
  font-size: 1.2rem;
  padding: 10px 50px;
}
.Privacypage_heading5 {
  font-size: 1.9rem;
  margin-left: 50px;
}
.Privacypage_paragraph5 {
  font-size: 1.2rem;
  padding: 10px 50px;
}
.Privacypage_heading6 {
  font-size: 1.9rem;
  margin-left: 50px;
}
.Privacypage_paragraph6 {
  font-size: 1.2rem;
  padding: 10px 50px;
}
.Privacypage_heading7 {
  font-size: 1.9rem;
  margin-left: 50px;
}
.Privacypage_paragrph7 {
  font-size: 1.2rem;
  padding: 10px 50px;
}
.Privacypage_heading8 {
  font-size: 1.9rem;
  margin-left: 50px;
}
.Privacypage_paragrph7 a{
  color: #38b5fe;
  text-decoration: underline;
}
.Privacypage_paragraph8 a{
  color: #38b5fe;
}
.Privacypage-address {
  font-size: 1.3rem;
  padding: 2px 50px;
}
.Privacypage_address3 {
  font-size: 1.3rem;
  padding: 2px 50px;
}
.Privacypage_paragraph8 {
  font-size: 1.3rem;
  padding: 10px 50px;
}
.Privacypage-address2 {
  font-size: 1.3rem;
  padding: 2px 50px;
}
@media only screen and (max-width: 768px) {
  .privacy_heading1 {
    font-size: 40px;
    margin-top: 5rem;
    margin-left: 2rem;
  }

  .privacy_paragraph1 {
    padding: 10px 2rem;
  }

  /* Add more responsive styles as needed */
}

@media only screen and (max-width: 480px) {
  .privacy_heading1 {
    font-size: 30px;
    /* margin-top: 3rem; */
    margin-left: 1rem;
    text-align: center;
  }

  .privacy_paragraph1 {
    padding: 10px 1rem;
    font-size: 1.1rem;
    text-align: center;
  }
  .privacy_content{
    margin-top: 10rem;
  }
  /* Add more responsive styles as needed */
}