.promotion_BgImage {
  position: relative;
  background-image: url(/src/Assets/Promotion\ background.jpg);
  width: 100%;
  height: 600px;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  font-family: "Poppins", sans-serif ;
  background-attachment: fixed !;
}
.promotion_BgImage::after {
  content: "";
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(198, 48, 48, 0.55);
}
.Promotion_Content{
  position: absolute;
  top: 73%;
  left: 22.5%;
  transform: translate(-50%, -50%);
  text-align: center;
  backdrop-filter: blur(300px); 
  background-color: rgba(255, 255, 255, 0.5);
}
.Promotion_Heading1 {
  font-size: 4rem;
  backdrop-filter: blur(4px);
  color: #fff;
  margin-top: -15rem;
  font-family: "Poppins", sans-serif;
}
.Promotion_Paragraph1 {
  font-size: 22px;
  width: 100%;
  font-size: larger;
  margin-left: 7rem;
  color: #fff;
  font-family: "Poppins", sans-serif;
}
.Prmotion_paragraph_2 {
  color: whitesmoke;
  font-size: 22px;
  /* letter-spacing: ; */
  
  font-family: "Poppins", sans-serif;
}
.Promotion_heading_1 {
  color: #38b6ff;
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  position: relative;
  top: 20px;
  /* padding: 10px 0px; */
}
.Promotion_Paragraph_3 {
  color: white;
  font-family: "Poppins";
  font-size: 22px;
  padding: 14px 0px;
}
.Promotion_heading-2 {
  color: #38b6ff;
  font-size: 30px;
  font-family: "Poppins", sans-serif;
  position: relative;
  top: 20px;
}
.Promotion_paragrapgh-4 {
  color: whitesmoke;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  padding: 16px 0px;
}
.Promotion_heading_3 {
  color: #38b6ff;
  font-size: 30px;
  position: relative;
  top: 20px;
  font-family: "Poppins", sans-serif;
}
.Promotion-paragrapg-5 {
  color: whitesmoke;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  padding: 16px 0px;
}
.Promotion_heading-4 {
  color: #38b6ff;
  position: relative;
  top: 20px;
  font-size: 30px;
  font-family: "Poppins", sans-serif;
}
.Promotion_paragraph-6 {
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  padding: 16px 0px;
}
.Promotion_heading-5 {
  color: #38b6ff;
  font-size: 30px;
  position: relative;
  top: 20px;
  font-family: "Poppins", sans-serif;
}
.Promotion_paragrapg-7 {
  color: whitesmoke;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  padding: 17px 0px;
}
.Promotion-heading_6 {
  color: #38b6ff;
  font-size: 30px;
  position: relative;
  top: 20px;
  font-family: "Poppins", sans-serif;
  font-size: 30px;
}
.Promotion_paragraph-8 {
  color: whitesmoke;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  padding: 17px 0px;
}
.Promotion_paragraph-9 {
  color: whitesmoke;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  position: relative;
  top: 20px;
}
.Promotion_heading-7 {
  color: whitesmoke;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
}
.Promotion_heading-7 {
  color: whitesmoke;
  font-size: 24px;
  padding: 20px 0px;
  font-family: "Poppins", sans-serif;
  line-height: normal;
  font-weight: 700;
  position: relative;
  top: 20px;
}
.Promotion_Paragraph {
  position: relative;
  top: 40px;
  font-family: "Poppins", sans-serif;
  padding: 10px 30px;
}
.Promotion_Form_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
.Promotion_contact_body {
  position: relative;
  background: url(/src/Assets/let\ build\ background.png);
  min-height: 100vh;
  padding: 50px 150px;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  flex-direction: column;
  background-attachment: fixed;
}
.Promotion-Form_contactInfo {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.Promotion_Form-box {
  position: relative;
  padding: 20px 0;
  display: flex;
}
.Promotion_form_text {
  color: whitesmoke;
  position: relative;
  /* left: -210px; */
  margin-left: -120px;
}
.Promotion-Form-text2 {
  font-family: "Poppins", sans-serif;
  font-size: 60px;
}
.Promotion_form-text3 {
  font-family: "Poppins", sans-serif;
  font-size: 55px;
}
.Promotion_form_paragrapg {
  font-family: "Poppins", sans-serif;
}
.Promotion-form-span {
  font-family: "Poppins", sans-serif;
  color: #38b6ff;
}
.Promotionpage_form {
  background: rgba(27, 31, 34, 0.8);
  width: 500px;
  margin: 50px auto;
  max-width: 97%;
  border-radius: 4px;
  padding: 55px 30px;
}
.responsive_form_promotionpage {
  border: 2px solid #386fbb;
  padding: 10px 20px;
  border-radius: 30px;
}
.PromotionPage_form_title {
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
  letter-spacing: 2px;
  display: inline-block;
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: 600;
}
.Promotionpage_half {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}
.PromotionPage_items_form {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
.Promotionpage_inputboxes {
  border-radius: 4px;
  border: 1px solid white;
  outline: none;
  padding: 19px;
  width: 100%;
  height: 40px;
  background: whitesmoke;
  font-size: 14px;
}
.Promotionpage_form_full {
  margin-bottom: 24px;
}
.promotionpage_textarea_form {
  background: whitesmoke;
  border-radius: 10px;
  outline: none;
  padding: 12px 16px;
  width: 100%;
  height: 150px;
  font-size: 17px;
}
.Promotionpage_option {
  width: 100%;
  height: 20px;
  background: whitesmoke;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid white;
  outline: none;
  padding: 19px;
}
.Promotionpage_form_action {
  margin-bottom: 20px;
}
.Promotionpage_action_input {
  background: #38b6ff;
  border-radius: 30px;
  border: 1px solid white;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  height: 37px;
  padding: 0 20px;
  letter-spacing: 1px;
  outline: 0;
  margin-left: 142px;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .promotion_BgImage {
    width: 1200px;
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 100%;
  }

  .Promotion_Heading1 {
    font-size: 40px;
    position: relative;
    left: 160px;
    padding: 0px 10px;
  }

  .Promotion_Paragraph1 {
    position: relative;
    left: 170px;
  }

  .Promotion_Paragraph_all {
    margin-left: 60px;
    width: 1000px;
  }
 
}

/* Media queries for large screens */
@media (min-width: 1025px) {
  .Promotion_Heading1 {
    font-size: 4rem;
    margin-top: -15rem;
    left: 0; 
  }
 
  .Promotion_Paragraph1 {
    font-size: 22px;
    width: 100%;
    left: 0; 
  }
}
@media (max-width: 768px) {
  .Promotion_Heading1{
    font-size: 2rem;
    left: 0;
    padding: 0;
    text-align: center;
    margin-left: 12rem;
  }
  .Promotion_Content{
    top: 55%;
    text-align: center;
  }
  .Promotion_Paragraph1 {
    
    width: 80%;
    left: 0; 
  }
}
