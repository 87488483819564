/* Add this to your CSS file or use a styling solution like Styled Components */

.About_page_container {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url("/src/Assets/about-img.jpeg") center/cover no-repeat;
  overflow: hidden;
  backdrop-filter: blur(5px);
  height: 500px;
  background-attachment: fixed !important;
}

.Aboutpage_content {
  color: #fff;
  backdrop-filter: blur(2px);
}

.aboutpage_heading1 {
  font-size: 65px;
  position: relative;
  margin-left: 50px;
  margin-top: 200px;
  letter-spacing: 2px;
  font-family: "Poppins", sans-serif;
}

.aboutpage_paragraph1 {
  font-size: 1.2em;
  padding: 10px 30rem;
  line-height: 1.9rem;
  margin-left: -27rem;
  font-family: "Poppins", sans-serif;
}
.about-us {
  text-align: center;
  margin-top: -5rem;
  background-color: black;
  color: #f0f0f0;
  padding: 6rem;
}
.aht {
  font-size: 3em;
  font-family: "Poppins", sans-serif;
}
.ah {
  padding: 10px 0px;
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-family: "Poppins", sans-serif;
}
.Aboutpage-card-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  background-color: black;
}

.aboutpage_card {
  width: 22rem;
  height: 25rem;
  background: #07182e;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  border-radius: 50px;
}
.aboutpage_card:hover {
  transform: translateY(-5px);
  filter: brightness(1.3);
}
.aboutpage_card h3:hover {
  color: skyblue;
}
.aboutpage_card h3 {
  z-index: 1;
  font-family: "Poppins", sans-serif;
  color: white;
  font-size: 1.7em;
  margin-top: 4rem;
  text-align: center;
  margin-right: 7rem;
}
.aboutpage_card p {
  z-index: 1;
  font-family: "Poppins", sans-serif;

  color: white;
  line-height: 1.5em;
  padding: 20px 2em;
}

.aboutpage_card::before {
  content: "";
  position: absolute;
  width: 100px;
  /* background-image: linear-gradient(-180deg, rgb(0, 183, 255), rgb(255, 48, 255)); */
  height: 130%;
}
.ourap{
  text-align: center;
}
.aboutpage_card::after {
  content: "";
  position: absolute;
  background: #07182e;
  inset: 0;
  border-radius: 49px;
  background: linear-gradient(#212121, #212121) padding-box,
    linear-gradient(145deg, transparent 35%, #e81cff, #40c9ff) border-box;
  border: 3px solid transparent;
}
@media only screen and (max-width: 768px) {
  .Aboutpage_content {
    padding: 10px 5px;
    align-items: center;
    text-align: center;
    /* margin-top: -8rem; */
    margin-top: 4rem;
  }
  .content h1 {
    text-align: center;
    margin-right: 0;
    font-size: 1.4em;
  }

  .content p {
    font-size: 1em; /* Adjust font size for better visibility on smaller screens */
  }
  .aboutpage_card {
    margin-top: 3rem;
  }
  .aboutpage_card h3 {
    text-align: center;
    align-items: center;
  }
  .aht {
    font-size: 2.3rem;
  }
  .ah {
    font-size: 1em;
    text-align: justify;
    width: 100%;
  }
  .aboutpage_heading1 {
    text-align: center;
    margin: 0;
  }
  
  .aboutpage_paragraph1 {
    padding: 0;
    margin: 0;
    text-align: center;
  }
  .about-us {
    text-align: center;
    margin-top: -5rem;
    background-color: black;
    color: #f0f0f0;
    padding: 6rem 1rem 1rem 1rem;
  }
}
