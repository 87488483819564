.PricingHome_main_body{
   /* height: 100%; */
}
.PricingHome_Card_body {
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: 100vh; */
  padding: 10px 0px;
  font-family: "Poppins", sans-serif;
}
.Pricinghome_page_container {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 1100px;
  
  font-family: "Poppins", sans-serif;
}
.pricinghomepage_span2 {
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
}
.pricinghomepage_card {
  width: 301px;
  margin: 6px;
  /* border: 1px solid blue; */
  padding: 40px 30px;
  transition: transform 0.1s ease;
  position: relative;
  border-radius: 70px;
  border: 3px solid transparent;
  background: linear-gradient(#212121, #212121) padding-box,
              linear-gradient(-120deg, transparent 0%,#058dcd ,#783bc9) border-box;
}
.pricinghomepage_card:hover {
  transform: translateY(-20px);
  color: #058dcd;
}
.pricinghomepage_heading3:hover {
  color: #058dcd;
}
.pricinghomepage_heading5:hover{
   color: #38b6ff;
}
.pricinghomepage_imgbx {
  position: relative;
  text-align: center;
}
.pricinghome_top_body_content{
  top: 70%;
}
.pricinghomepage_contentbx {
  position: relative;
  margin-top: 20px;
  text-align: center;
  color: whitesmoke;
}
.pricinghomepage_a {
  display: inline-block;
  padding: 10px 70px;
  height: 40px;
  text-decoration: none;
  color: whitesmoke;
  margin-top: 15px;
  text-align: center;
  background-color: transparent;
  border: 2px solid #38b6ff;

  border-radius: 10px;
  text-align: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
}
.pricinghomepage_a:hover {
  background-color: #38b6ff;
}
.pricinghomepage_heading3 {
  font-family: "Poppins", sans-serif;
  font-size: 20px !important;
  color: whitesmoke;
  font-weight: 700 !important;
  /* letter-spacing: 2px; */
}
.pricinghomepage_heading4 {
  letter-spacing: 2px;
  font-family: "Poppins", sans-serif;
  font-size: 70px;
  font-weight: 700 !important;
}
.pricinghome_heading1 {
  color: #9747ff;
  font-family: Poppins;
  font-size: 29px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0px 90px;
  font-family: "Poppins", sans-serif;
}
.pricinghomepage_heading5 {
  font-family: "Poppins", sans-serif;
  font-size: 17.6px;
  color: whitesmoke;
  white-space: nowrap;
}
.pricinghome_heading2 {
  color: whitesmoke;
  padding: 10px 90px;
  font-size: 45px;
  font-family: "Poppins", sans-serif;
}
.pricinghome_span {
  color: #38b6ff;
  line-height: normal;
  font-family: "Poppins", sans-serif;
  font-size: 45px;
}
/* @media (max-width: 708px) {
  .pricinghomepage_card {
    width: 100%;
    margin: 10px;
    padding: 20px;
    border-radius: 80px;
  }
  .PricingHome_main_body{
    height: 100vh;
 }
  .pricinghome_top_body_content {
    top: 50%;
  }

  .pricinghome_heading1,
  .pricinghome_heading2 {
    padding: 0 20px;
  }
}

@media (max-width: 480px) {
  .pricinghomepage_card {
    padding: 15px;
  }

  .pricinghomepage_heading4 {
    font-size: 40px;
  }

  .pricinghome_heading1,
  .pricinghome_heading2 {
    padding: 0 10px;
  }
} */
@media (max-width: 480px) {
  .pricinghome_heading2 {
    color: whitesmoke;
    padding: 1px 30px;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
  }
  .pricinghome_span {
    color: #38b6ff;
    line-height: normal;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
  }
  .pricinghome_heading1 {
    color: #9747ff;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 0px 30px;
    font-family: "Poppins", sans-serif;
  }
}
@media (max-width: 320px) {
  .pricinghomepage_card {
    width: 287px;
    margin: 6px;
    /* border: 1px solid blue; */
    padding: 50px 15px;
    transition: transform 0.1s ease;
    position: relative;
    border-radius: 70px;
    border: 3px solid transparent;
    background: linear-gradient(#212121, #212121) padding-box,
                linear-gradient(-120deg, transparent 0%,#058dcd ,#783bc9) border-box;
  }

}