#NewContactPage_main_body {
  width: 100%;
  /* background-image: url(/src/Assets/contact\ us\ background.jpg); */
  background-size: cover;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  /* margin-top: 7rem; */
}
#newcontactpage_card_heading {
  color: #38b6ff;
  font-size: 29px;
  font-family: "Poppins", sans-serif;
}
#newcontactpage_link_button {
  position: relative;
  top: -20px;
  font-size: 20px;
  color: #38b6ff;
}
#newcontactpage_card_paragraph {
  color: whitesmoke;
  font-size: 15px;
  padding-bottom: 30px;
  font-family: "Poppins", sans-serif;
}
#newcontactpage_container {
  /* margin-top: 10px;
  background: rgba(12, 124, 188, 0.229);
  border-radius: 5%;
  backdrop-filter: blur(4px);
  padding: 40px;
  height: 600px;
  max-width: 520px;
  width: 100%;
  box-shadow: 0 15px 25px rgba(97, 97, 97, 0.122);
  border-radius: 60px;
  border: 1px solid #9747ff;
  background: linear-gradient(
    123deg,
    rgba(58, 129, 191, 0.7) 6.2%,
    rgba(65, 48, 90, 0.7) 93.68%
  ); */
}
#newcontactpage_form_inputs {
  width: 100%;
  padding: 10px 20px;
  margin: 10px 0;
  display: inline-block;
  border: none;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: transparent;
  border: 1px solid #fff;
}
#newcontactpage_label3 {
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
}
#newcontactpage-label2 {
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
}
#newcontactpage_label {
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
}
#newcontactpage_label4 {
  font-family: "Poppins", sans-serif;
  color: whitesmoke;
}
#newcontactuspage_select {
  width: 100%;
  padding: 10px 20px;
  margin: 10px 0;
  display: inline-block;
  border: none;
  border-radius: 10px;
  box-sizing: border-box;
  border: 1px solid #fff;
  background-color: transparent;
}
option{
  background-color: #fff;
}
#newcontactpage_form_button {
  background: #38b6ff;
  padding: 8px 60px;
  /* height: 50px; */
  /* width: 140px; */
  color: #fff;
  border-radius: 40px;
  cursor: pointer;
  font-size: 20px;
  outline: none;
  transition: background-color 0.3s ease;
  margin: 10px 130px;
  /* width: 100%; */
  border: none;
}
#newcontactpage_form_button:hover{
  background-color: rgb(15, 192, 15);
}
/* 1st media  */
@media (min-width: 300px) and (max-width: 600px) {
  #newcontactpage_form_button {
    position: relative;
    left: -80px;
    top: 10%;
  }
  #newcontactpage_container {
    height: 100vh;
    width: 100%;
  }
}
