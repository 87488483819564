.clienttrust_all_product {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  
  margin-top: 50px;
}
.clienttrust_all_product p{
  font-size: 0.8em;
}
/* .clientrust_product-heading{
  font-size: 1 rem !important;
} */
.clienttrust_product {
  overflow: hidden;
  font-family: "Poppins", sans-serif;
  color: #fff;
  text-align: center;
  width: 300px;
  height: 200px;
  padding: 2rem;
  display: flex;
  font-size: 1.5em;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 3.4rem;
  margin: 1rem;
  /* border: 1px solid #9747ff;#40c9ff ,e81cff*/
  border: 2px solid transparent;
  background: linear-gradient(#212121, #212121) padding-box,
              linear-gradient(-120deg, transparent 0%,#382659, #0d5477) border-box;
  /* box-shadow: 2px 6px 20px blue; */
  transition: transform 0.8s ease;
}
.clienttrust_product:hover {
  transform: translateY(-20px);
}
.clienttrust_product_paragraph_4 {
  font-size: 0.7rem;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-top: 30px;
}
.clienttrust_product_paragraph1 {
  font-size: 0.9rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-top: 30px;
}
.clienttrust_product_paragraph2 {
  font-size: 0.9rem;
  font-family: "Poppins", sans-serif;
  margin-top: 30px;
}
.clienttrust_product_paragraph3 {
  font-size: 0.9rem;
  font-family: "Poppins", sans-serif;
  margin-top: 30px;
  line-height: normal;
  text-align: center;
  font-weight: 400;
}
.clienttrust_paragraph1 {
  font-family: "Poppins", sans-serif;
  font-size: 23px;
  line-height: 1.5;
  font-weight: 500;
  padding: 40px 150px;
  text-align: center !important;
  /* text-align: center; */
}
.clienttrust_span {
  color: #38b6ff;
  font-weight: 700;
  font-size: 50px;
  font-family: "Poppins", sans-serif;
}
.clienttrust_heading1 {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  margin-left: 90px;
}
.clienttrust_paragraph2 {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  padding: 2px 100px;
  font-weight: 500;
}
@media screen and (max-width: 768px) {
  .clienttrust_paragraph1,.clienttrust_paragraph2 {
    padding: 40px 20px; 
    text-align: center;
  }
  .clienttrust_heading1{
    padding: 0;
    margin: 0 2.5rem;
  }
}
@media screen and (max-width: 400px) {
  .clienttrust_paragraph1{
    font-size: 15px;
    text-align: justify;
  }
  .clienttrust_heading1{
    font-size: 20px;
    
  }
  .clienttrust_span {
    color: #38b6ff;
    font-weight: 700;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
  }
  .clienttrust_paragraph2 {
    font-family: "Poppins", sans-serif;
    text-align: left;
    font-size: 15px;
    padding: 12px 20px;
    font-weight: 500;
  }
 
 
}